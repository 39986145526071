import 'reflect-metadata';

// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/http';
import '@angular/router';
import '@angular/compiler';
import '@angular/forms';
import '@angular/upgrade';
import '@angular/material';
import 'angular2-in-memory-web-api';

//Other
import 'rxjs';
import 'core-js';

import 'ng2-bootstrap';
import 'angular2-recaptcha';
import 'angular2-text-mask';
import 'ng2-toastr';
import 'moment';
import 'immutable';

//viedeoangular
import 'videogular2/core';
import 'videogular2/controls';
import 'videogular2/buffering';
import 'videogular2/overlay-play';